import axios, { AxiosRequestConfig } from 'axios';
import { useLoading } from 'vue-loading-overlay';
// eslint-disable-next-line import/no-cycle
import store from '@/store';

const $loading = useLoading();

const HTTP = axios.create({
  baseURL: 'https://rf4ilkl2zvedhrbptlbups2k2y0keqcb.lambda-url.us-east-1.on.aws/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

const GET = (route: string, params: AxiosRequestConfig['params']) =>
  new Promise((resolve, reject) => {
    HTTP.get(route, {
      params,
    })
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  });

const POST = async (route: string, params: AxiosRequestConfig['params']) =>
  new Promise((resolve, reject) => {
    const loader = $loading.show({});
    const user = { name: store.getters.user.account.name, email: store.getters.user.account.username };
    HTTP.post(route, { user, ...params })
      .then(res => {
        resolve(res.data || res);
      })
      .finally(() => {
        loader.hide();
      });
  });

const PUT = async (route: string, params: AxiosRequestConfig['params']) =>
  new Promise((resolve, reject) => {
    HTTP.put(route, params).then(res => {
      resolve(res);
    });
  });

// request interceptor
HTTP.interceptors.request.use(
  config =>
    // config.headers["HEADER-NAME"]
    config,
  error =>
    // Do something with request error
    Promise.reject(error),
);

// response interceptor
HTTP.interceptors.response.use(
  response => response,
  error => {
    console.log('error', error);
    return Promise.reject(error);
  },
);

export default {
  GET,
  PUT,
  POST,
};

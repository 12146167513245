import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store/index';
import ProjectFeed from '@/views/Projects/views/ProjectsFeed.vue';
import ProjectsPublished from '@/views/Projects/views/ProjectsPublished.vue';
import Login from '@/views/Login/Login.vue';
import Project from '@/views/Project/Project.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'app',
    component: () => import(/* webpackChunkName: "about" */ '../Layout.vue'),

    // redirect: '/login',
    beforeEnter: async (to, from, next) => {
      if (!store.getters.user && to.name !== 'Login') await store.dispatch('loginMsalUser');
      return next();
    },
    children: [
      {
        path: '',
        name: 'Login',
        component: Login,
      },
      {
        path: 'project/:projectId',
        name: 'Project View',
        component: Project,
      },
      {
        path: 'projects',
        name: 'Projects',
        redirect: '/projects/feed',

        component: () => import(/* webpackChunkName: "about" */ '../views/Projects/Projects.vue'),

        // eslint-disable-next-line arrow-body-style

        children: [
          {
            path: 'feed',
            name: 'All Projects',
            component: ProjectFeed,
          },
          {
            path: 'drafts',
            name: 'My Projects',
            component: ProjectFeed,
          },
          {
            path: 'published',
            name: 'Projects Published',
            component: ProjectsPublished,
          },
        ],
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;

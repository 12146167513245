/* eslint-disable import/prefer-default-export */
import { createApp } from 'vue';
import IncrediUI from 'incredi-ui';
import VueLoading from 'vue-loading-overlay';
import { app } from '@/bootloader';
import router from './router';
import store from './store';
import './style/index.css';
import 'vue-loading-overlay/dist/vue-loading.css';
import { CustomNavigationClient } from './router/NavigationClient';
import msalInstance from './service/msal';
import { msalPlugin } from './plugins/msalPlugin';

const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);

app.use(msalPlugin, msalInstance).use(IncrediUI).use(VueLoading).use(store).use(router).mount('#app');

import { createStore } from 'vuex';
// eslint-disable-next-line import/no-cycle
import HTTP from '../service/API';
import { app } from '../bootloader';

export default createStore({
  state: {
    user: null,
    projects: [],
    categories: [],
  },
  getters: {
    categories(state) {
      return state.categories;
    },
    user(state) {
      return state.user;
      return {
        account: {
          name: 'Yair',
          username: 'yair.mayer@incredibuild.com',
        },
      };
    },
    projects(state) {
      return state.projects;
    },
  },
  mutations: {
    user(state, payload) {
      state.user = payload;
    },
    projects(state, payload) {
      state.projects = payload;
    },
    categories(state, payload) {
      state.categories = payload;
    },
  },
  actions: {
    async getCategories({ commit }) {
      const categories = await HTTP.POST('', {
        action: 'category_get',
      });
      commit('categories', categories);
    },
    async getProjects({ commit }) {
      const projects = await HTTP.POST('', {
        action: 'projects_get',
        projectId: 0,
        noteaboutprojectId: 'projectId 0 or not exist searches all',
      });
      commit('projects', projects);
    },
    async getProject({ commit, state }, id) {
      const result: any = await HTTP.POST('', {
        action: 'projects_get',
        projectId: id,
        noteaboutprojectId: 'projectId 0 or not exist searches all',
      });
      const project = result[0];
      const projectIndex = state.projects.findIndex((e: any) => e?.id === project?.id);
      commit('projects', projectIndex === -1 ? [...state.projects, project] : state.projects.map((e: any) => (e?.id === project.id ? project : e)));
    },
    async loginMsalUser({ commit }) {
      const loginResponse = await app.config.globalProperties.$msal.instance.loginPopup();
      commit('user', loginResponse);
    },
    async logoutMsalUser({ commit }) {
      // const loginResponse = await msalInstance.logoutPopup();
      // console.log('logoutMsalUser', loginResponse);
      commit('user', null);
    },
  },
  modules: {},
});

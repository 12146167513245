import * as msal from '@azure/msal-browser';

const env = process.env.NODE_ENV;
const msalConfig = {
  auth: {
    authority: 'https://login.microsoftonline.com/1deafb82-385b-4ee7-abda-14dff9a229e3',
    clientId: 'd362f6b7-be56-4cc7-8156-ea30c38e26d7',
    redirectUri: env === 'development' ? 'http://localhost:8080' : 'https://hackcelerate.incredibuild.com/', // Must be registered as a SPA redirectURI on your app registration
    postLogoutRedirectUri: env === 'development' ? 'http://localhost:8080' : 'https://hackcelerate.incredibuild.com/', // Must be registered as a SPA redirectURI on your app registration
  },
};

export default new msal.PublicClientApplication(msalConfig);

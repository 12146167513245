<template>
  <router-view />
</template>
<script lang="ts" setup></script>
<style lang="scss">
#app {
  background-color: #f8fafb;
  display: flex;
}
</style>

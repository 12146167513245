export const CATEGORIES_OPTIONS_COLORS = {
  'Re-think "Acceleration"': '#F63553',
  'Accelerate New Things With IB Product': '#FFA105',
  'Accelerate IB Company - How We Do Things': '#5E8FD3',
  'Accelerate Development of a Feature': '#155EC2',
};

export const CATEGORIES_OPTIONS: Array<keyof typeof CATEGORIES_OPTIONS_COLORS> = [
  'Re-think "Acceleration"',
  'Accelerate New Things With IB Product',
  'Accelerate IB Company - How We Do Things',
  'Accelerate Development of a Feature',
];
